body {
    background: #f6f8ff;
}
html {
    scroll-behavior: smooth;
}

@media (min-width: 1440px) {
    .container-content {
        max-width: 1430px;
    }
}

@media (max-width: 1439px) {
    .container-content {
        max-width: 98%;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
}
.modal-backdrop {
    opacity: 0.8 !important;
}

.text-link {
    text-decoration: none;
    color: #2c7be5;
    background-color: transparent;
    cursor: pointer;
}
.text-link:hover {
    color: #1657af;
}

.container-title h1 {
    margin-bottom: 0;
}

.card-sm .card-body {
    padding: 10px 15px !important;
}

.input-group-sm .btn-photo {
    height: 31px;
}

.custom-file-input~.custom-file-label[data-browse]:after {
    content: 'Выбрать'
}

.custom-file-without-browse .custom-file-input~.custom-file-label[data-browse]:after {
    display: none;
}
.mx-datepicker {
    width: 100% !important;
    display: block !important;
}
.tooltip.b-tooltip {
    opacity: 1 !important;
}
.dropdown-toggle-hide-after .dropdown-toggle:after {
    display: none;
}