.cursor-pointer {
    cursor: pointer;
}

.cursor-drag-n-drop {
    cursor: grab;
}
.cursor-drag-n-drop:active {
    cursor: grabbing;
}
.br-0 {
    border-bottom: 0;
}