.icon {
    position: relative;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    text-align: center;
    vertical-align: middle;
    transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, opacity 200ms ease;
}

.icon--min {
    width: 24px;
    height: 24px;
}
