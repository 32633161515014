.container-table-scroll {
    height: 600px;
    margin: auto;
    overflow: auto;
}
.table-scroll {
    border-collapse: collapse;
    width: 100%;
}
.table-scroll thead th {
    background: #fff;
    position: sticky;
    top: 0;
}

.table-sm td, .table-sm th {
    padding: 7px;
}

.table td, .table th {
    padding: 9px;
}